/* Begin of Fill information section  */
.Fill_Page{
    background-image: url('../Images/body-pic.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
}


/* header#header {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 9;
    display: none;
} */

/* section.fill-info-sec {
    background-image: url(../Images/banner-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
     padding: 400px; 
    height: 980px;
    width: 100%;
    object-fit: cover;
} */
/* .logo img {
    width: 28%;
    margin: 0 auto;
}

.logo {
    text-align: center;
    padding-bottom: 110px;
} */

.Fill_Page .form-head h2 {
    font-size: 42px;
    color: #FFFFFF;
    font-family: 'Helvetica Rounded LT Std';
    font-weight: 700;
    text-transform: uppercase;
}

.Fill_Page .form-head h4 {
    font-size: 24px;
    color: #FFFFFF;
    font-family: 'Helvetica Rounded LT Std';
    text-transform: uppercase;
}
.Fill_Page .filllllz{
    /* position: relative;
    top: -200px; */
    margin-top: 200px!important;
}

.Fill_Page .all-form {
    width: 50%;
    margin: 0 auto;
}

.Fill_Page .form-group input {
    border: none;
    padding: 25px 16px;
    border-radius: 9px;
}

.Fill_Page .form-group .ht-submzzz{
    height: 45px;
}

.Fill_Page .form-group input::placeholder {
    font-size: 12px;
    color: #C4C4C4;
    font-family: 'Helvetica Rounded LT Std';
    letter-spacing: 1px;
    text-transform: uppercase;
}

.Fill_Page .form-head {
    text-align: center;
    padding-bottom: 21px;
}
/* footer.footer {
    background-image: none;
    padding-top: 503px;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
} */



/* End of Fill information section  */