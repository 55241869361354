@media only screen and (max-width: 1150px) {
    header#header .navbar-wrapper ul li a {
        font-size: 11px;
    }

    /* footer  */
    .ftr-logoooooooo {
        width: 100%;
        height: auto !important;
    }

    footer.footer ul li a {
        font-size: 11px !important;
    }

    footer.footer ul li {
        font-size: 11px !important;
    }

    footer .ftr-logoooooooo {
        max-width: 150px;
    }

    footer .rights-text {
        font-size: 11px;
    }

    footer .ico_foooter {
        font-size: 20px !important;
    }

    footer .footer-widget-2-wrapper ul {
        margin-top: 20px;
    }

    footer div#copyright a {
        font-size: 11px;
    }

}

.header-small {
    display: none;
}

.swip_header_sml {
    display: none;
}

@media only screen and (max-width: 992px) {

    /* dp_none */
    .coming img.ice {
        display: none;
    }

    .coming {
        padding-bottom: 350px;
    }

    /* footer */
    footer.footer h4 {
        margin-top: 1rem;
    }

    #copyright img {
        display: none;
    }

    footer .pill .col-lg-3 {
        padding-left: 1rem !important;
    }

    footer .row .align-self-end {
        text-align: left !important;
    }

    footer .rightline-ftr {
        display: none;
    }

    footer .flx-line-bx {
        justify-content: flex-start;
    }

    footer.footer {
        padding-top: 283px;
    }

    /* footer ends here */

    .bg-red-res-hdr {
        background-color: #fff;
        color: black;
        margin-top: 2px;
        margin-right: 6px;
    }

    .header-full {
        display: none;
    }

    .HomeContent {
        padding-top: 4rem;
    }

    .universal_banner .page-head {
        padding-top: 8rem;
    }

    /* .universal_banner .page-head h2{
        margin-top: 130px;
    } */

    .header_comp {
        position: fixed;
        top: 0px;
        width: 100%;
        z-index: 999;
    }

    .swip_header_sml {
        display: flex;
        justify-content: center;
        margin-top: 13px;
    }

    .facebook_ico_headerr {
        color: #202076;
    }

    .insta_ico_headerr {
        color: #ff607a99;
    }

    .header-small {
        display: block;
    }

    .header .flx-header-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .header-small .img_respon {
        width: 44px;
        padding: 0px !important;
        background-color: #fff0 !important;
        /* width: 30px; */
    }

    .header-small .line-small-hdr {
        margin-left: 10px;
    }

    .header-small .bg-red-hdr {
        background-color: #2798db;
        color: white;
        display: flex;
        justify-content: space-around;
        padding: 0.5rem 0.5rem;
    }

    .header-small .align-center {
        align-self: center;
    }

    .header-small .clr_balluu {
        color: white;
        font-size: 18px;
    }

    .header-small .bt-book-instant-mob {
        background-color: #DF1921;
        font-size: 10px;
        padding: 0.5rem 0.75rem;
        margin-left: 1rem;
    }

    .clr-hom-sml-hdr {
        color: #2798db;
        text-decoration: none;
    }

    .clr-hom-sml-hdr :hover {
        color: #202076 !important;
        text-decoration: none;
        /* border-bottom: 1px solid #df1921; */
        /* padding-bottom: 0.5rem; */
    }

    .list-style-type-circle {
        list-style-type: circle;
    }

    .flx-1 {
        flex: 1;
    }

    .flx-2 {
        flex: 3.5;
    }

    .flex-end-hdr-smll {
        justify-content: flex-end;
        cursor: pointer;
        color: #DF1921;
    }

    .flex-end-hdr-smll:hover {
        color: #202076;
    }

    .close_btn_sml_hdr {
        float: right;
        margin-right: 1rem;
    }

    .MuiDrawer-root>.MuiPaper-root {
        border-radius: 0px 0px 8px 8px;
    }

    .swip_header_sml .css-1ytz6dp {
        background-color: #2798db;
    }

    .pos-rel-top-four {
        position: relative;
        top: 3px;
        left: 12px;
        font-size: 12px;
    }

    .content_control_swiphead_res_header {
        background-color: #00000012;
        width: 100vw;
        height: 100%;
        padding: 10px 30px;
    }

    .flx_swipheader_res {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 1.5rem;
    }

    .header-small .flx_profile_img {
        place-content: center;
        align-items: center;
    }

    .header-small .flx_profile_img .profile_image_headers {
        margin-right: 0.5rem;
        position: static;
    }
}


/* // second header for responsive ends*/