/* Thank You Css Start Here */
.thank_you {
  padding: 60px 0;
  position: relative;
}
.thank_you .thankyou_box {
  background: #f8f8f8;
  padding: 50px 60px;
  border-radius: 10px;
}
.thank_you .thankyou_box p {
  margin: 0;
  color: #4a4a4a;
  font-size: 16px;
  font-weight: 500;
  width: fit-content;
  margin: auto;
  margin-bottom: 0;
}

.thank_you .thankyou_box p i {
  color: #fca4b7;
  text-decoration: underline;
  font-weight: 600;
}
.thank_you img.we1 {
  position: absolute;
  left: -3%;
  bottom: -20%;
  width: 11%;
}
.thank_you img.we2 {
  position: absolute;
  right: 0;
  bottom: 20%;
  width: 10%;
}
/* Thank You Css End Here */
