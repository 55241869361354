/* Gallery Css Start Here */
.gallery_sec {
  padding: 30px 0 60px;
  position: relative;
}
img.we1 {
  position: absolute;
  bottom: 0;
  left: -6%;
  animation: up-down linear 4s infinite;
  z-index: 9;
}

.gal-imgzzzz{
  height: 360px;
  object-fit: cover;
}

img.we2 {
  position: absolute;
  right: 0px;
  top: 10%;
  z-index: -1;
  animation: up-down linear 4s infinite;
  z-index: 9;
}
.gallery_sec .heading_wrapper {
  padding-bottom: 60px;
  text-align: center;
}
.gallery_sec .heading_wrapper h3 {
  font-size: 65px;
  color: #fc9eb2;
}
.gallery_sec .heading_wrapper h2 {
  color: #165eb9;
  text-transform: uppercase;
}
.gallery_sec .gallery_box img {
  border-radius: 10px;
}
.gallery_sec .gallery_box {
  position: relative;
  margin-bottom: 80px;
}
.gallery_sec .gallery_box:before {
  content: "";
  background: #fbd1d6;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  z-index: -1;
  transform: rotate(5deg);
  transition: 0.6s;
}
.gallery_sec .gallery_box:hover:before {
  transform: rotate(-5deg);
}
.gallery_sec .gallery_box.blue:before {
  background: #4e85ca;
}

.GalleryPage .universal_banner .page-head h2{
  text-transform: capitalize;
}
/* Gallery Css End Here */
