#card-errors {
	text-align: center;
	margin-bottom: 30px;
	color: #dc3545 !important;
}

.title {
	text-align: left;
	font-size: 32px;
	font-weight: bold;
}
.introduction ul {
	margin: 0;
	padding: 0;
	list-style: none;
	padding-left: 20px;
	text-align: left;
}
.introduction ul li {
	list-style: initial;
	font-size: 22px;
	padding-bottom: 10px;
}
.introduction ul li a {
	color: white;
	font-size: 22px;
	font-style: italic;
}
.introduction ul li a:hover {
	color: white;
	font-size: 24px;
	font-style: normal;
}

.introduction ul li a:visited {
	color: white;
	font-size: 22px;
	font-style: italic;
}

.leftSection {
	display: flex !important;
	flex: 1 !important;
	padding: 2% 0 !important;
}

.rightSection {
	display: flex !important;
	flex: 1 !important;
	justify-content: center !important;
	padding: 2% 0 !important;
}

/*Top Header container */
#top-header-container {
	border-bottom: 1px solid;
	margin: 0 20px;
	height: 7%;
}
h4.MuiTypography-root {
	margin: 10px !important;
	text-shadow: 0.1em 0.1em 0.2em rgba(0, 0, 0, 0.6);
	font-weight: 500;
}
/*Top Header container */

.FormGroup {
	margin: 0;
	padding: 5px 0;
	border-style: none;
	will-change: opacity, transform;
}
.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin: 15px;
}

.field {
	height: 50px;
	padding: 0;
	margin: 0 5px;
	width: 100%;
}

.MuiButton-root {
	margin: 20px !important;
}

/* output container */
#output-container {
	padding: 0;
	margin: 0;
	color: #3ce005;
	font-size: 12px;
	font-family: sans-serif;
	display: flex;
}
.MuiPaper-elevation3 {
	height: 100vh;
	margin: 20px;
	background-color: #000 !important;
	color: #51ea1e !important;
	text-align: left;
	font-size: 20px;
}
.MuiPaper-elevation3 p {
	padding-bottom: 0;
	display: flex;
	font-size: 18px;
	font-weight: lighter;
	color: #0eff00;
	margin: 16px !important;
}
/* output container */

/*divider*/
.MuiDivider-root {
	border: none;
	height: 2px !important;
	margin: 0 !important;
	flex-shrink: 0;
	background-color: white !important;
	border-top: 1px solid #fff !important;
}
#console-header h2 {
	margin: 0 !important;
	padding: 20px 0;
	background-color: #000;
}
