/* Privacy Css Start Here */
.privacy_sec {
  padding: 60px 0;
}
.privacy_sec .heading_wrapper {
  padding-bottom: 30px;
  text-align: center;
}
.privacy_sec .heading_wrapper h2 {
  color: #165eb9;
  text-transform: uppercase;
}
.privacy_sec .content_wrapper {
  text-align: center;
}
/* Privacy Css End Here */
