.Congrats_Page{
    background-image: url('../Images/body-pic.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    min-height: 100vh;
}


.Congrats_Page header#header {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 9;
    display: none;
}

/* .Congrats_Page footer.footer {
    background-image: none;
    padding-top: 503px;
    background-size: 100% 100%;
    background-position: center center;
    background-repeat: no-repeat;
} */


.Congrats_Page .congrats-all {
    background-color: #ffffff;
    width: 38%;
    padding: 6px 25px;
    text-align: center;
    margin: 0 auto;
    border-radius: 10px;
}

.Congrats_Page .congrats-all h2 {
    font-size: 38px;
    color: #165EB9;
    font-family: 'Helvetica Rounded LT Std';
}

.Congrats_Page .cong-logo img {
    width: 15%;
    padding-bottom: 68px;
}
.Congrats_Page .cong-logo {
    text-align: center;
}

.Congrats_Page section.congrats-sec {
    padding-top: 250px;
}