/* Signin Css Start Here */
figure {
  margin-bottom: 0;
}
/* header {
  display: none;
}
.newsletter_sec .newsletter_box {
  display: none;
}
footer {
  display: none;
} */
.enter_email {
  height: 100vh;
}
.enter_email .img-Dv {
  position: relative;
}
.enter_email .imgBox figure img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  border-radius: 0;
  /* filter: brightness(0.5); */
}
.enter_email .LogoBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.enter_email .LogoBox figure img {
  width: 100%;
}
.enter_email .emailBox {
  padding: 0 170px 0 170px;
}
.enter_email .emailBox .heading_wrapper {
  text-align: center;
  padding-bottom: 30px;
}
.enter_email .emailBox .heading_wrapper h2 {
  padding-bottom: 10px;
  color: #165eb9;
}
.enter_email .emailBox .heading_wrapper h4 {
  font-size: 24px;
  color: #888888;
  font-family: "Helvetica Rounded LT Std";
  line-height: 34px;
}
.enter_email .emailBox .form-group .form-control {
  border: 1px solid #00000040;
  padding: 25px 20px;
  margin-bottom: 15px;
  border-radius: 50px !important;
  text-align: center;
  color: #6360606e;
}
.enter_email .emailBox .form-group .form-control::placeholder {
  font-size: 18px;
  color: #9b9b9bd5;
}
.enter_email .emailBox button {
  width: 100%;
  font-family: "Poppins";
  text-transform: capitalize;
  font-weight: 600;
  background: #165eb9;
  color: #fff;
  font-size: 18px;
  padding: 15px 0;
  transition: 0.7s;
  border: 2px solid #fff;
  cursor: pointer;
  border-radius: 50px;
}
.enter_email .emailBox button:hover {
  box-shadow: 0px 0px 0px 2px #165eb9;
}
.enter_email .emailBox .privacyDv {
  text-align: left;
  padding-top: 20px;
}
.enter_email .emailBox .privacyDv p {
  font-weight: 100;
}
.enter_email .emailBox .privacyDv a {
  color: #165eb9;
  font-weight: 600;
  text-decoration: none;
  opacity: 1 !important;
}
@media (max-width: 1250px) {
  .enter_email .emailBox {
    padding: 0 40px 0 40px;
  }
}
@media (max-width: 1100px) {
  .enter_email .emailBox .title h2 {
    font-size: 30px;
    line-height: 40px;
  }
  /* .enter_email .LogoBox figure img {
    width: 100%;
    padding: 0 40px;
  } */
}
@media (max-width: 768px) {
  .enter_email .img-Dv {
    display: none;
  }
  .enter_email .emailBox {
    padding: 60px 40px 60px 40px;
  }
  .enter_email .emailBox .privacyDv {
    position: unset;
    padding-top: 30px;
  }
  .enter_email .container-fluid {
    padding: 0 55px;
  }
}
@media (max-width: 575px) {
  .enter_email .container-fluid {
    padding: 0 30px;
  }
  .enter_email .emailBox {
    padding: 30px 10px 30px 10px;
  }
  .enter_email .emailBox .title h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .enter_email .emailBox .taglines h4 {
    font-size: 15px;
    line-height: 22px;
  }
  .enter_email .emailBox .taglines h4 span.big {
    font-size: 25px;
    line-height: 35px;
  }
  .enter_email .emailBox .privacyDv p {
    font-size: 14px;
    line-height: 24px;
  }
  .enter_email .emailBox .actionsLink {
    display: block;
    text-align: center;
  }
  .enter_email .emailBox .actionsLink label {
    font-size: 13px;
    margin-bottom: 10px;
  }
  .enter_email .emailBox .actionsLink .forgotDv a {
    font-size: 13px;
  }
}
/* Signin Css End Here */
