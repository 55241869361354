/* Signin Css Start Here */
figure {
  margin-bottom: 0;
}
/* header {
  display: none;
} */
/* .newsletter_sec .newsletter_box {
  display: none;
} */
/* footer {
  display: none;
} */
.signinSec {
  height: 100vh;
}

.signinSec .btn_signin{
  background-color: #fc9eb2!important;
  margin-top: 0.5rem;
}

.signinSec .img-Dv {
  position: relative;
}
.signinSec .imgBox figure img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  border-radius: 0;
  /* filter: brightness(0.5); */
}
.signinSec .LogoBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
.signinSec .LogoBox figure img {
  width: 100%;
  max-width: 170px;
}
.signinSec .signInBox {
  padding: 0 150px 0 150px;
}
.signinSec .signInBox .title h2 {
  color: #165eb9;
  padding-bottom: 15px;
}
.signinSec .signInBox .taglines h4 {
  font-weight: 400;
  font-size: 24px;
  color: #888888;
  line-height: 34px;
  padding-bottom: 40px;
  font-family: "Helvetica Rounded LT Std";
}
.signinSec .signInBox .taglines h4 span.big {
  font-weight: 700;
  color: #165eb9;
  font-size: 35px;
  line-height: 45px;
}
.signinSec .signInBox p {
  font-weight: 500;
  color: #000;
}
.signinSec .signInBox .taglines h4 strong {
  font-weight: 800;
  color: #165eb9;
}
.signinSec .signInBox .taglines p a {
  color: #165eb9;
  margin-left: 5px;
  font-weight: 700;
  text-decoration: none;
}
.signinSec .signInBox .form-group .form-control {
  border: 1px solid #00000040;
  padding: 25px 20px;
  color: #63606070;
  margin-bottom: 15px;
  border-radius: 8px;
}
.signinSec .signInBox .form-group .form-control::placeholder {
  font-size: 18px;
  color: #9b9b9bd5;
}
.signinSec .signInBox button {
  width: 100%;
  font-family: "Poppins";
  text-transform: capitalize;
  font-weight: 600;
  background: #165eb9;
  color: #fff;
  font-size: 18px;
  padding: 15px 0;
  transition: 0.7s;
  border: 2px solid #fff;
  cursor: pointer;
  border-radius: 50px;
}
.signinSec .signInBox button:hover {
  box-shadow: 0px 0px 0px 2px #165eb9;
}
.signinSec .signInBox .actionsLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.signinSec .signInBox .actionsLink .forgotDv a {
  color: #bbbbbb;
  font-weight: 400;
  text-decoration: none;
}
.signinSec .signInBox .actionsLink .form-group {
  margin-bottom: 0;
}
.signinSec .signInBox .actionsLink label {
  margin-bottom: 0;
  font-weight: 400;
  color: #bbbbbb;
}
.signinSec .signInBox .privacyDv {
  text-align: left;
  padding-top: 20px;
}
.signinSec .signInBox .privacyDv p {
  font-weight: 100;
}
.signinSec .signInBox .privacyDv a {
  color: #165eb9;
  font-weight: 600;
  text-decoration: none;
  opacity: 1 !important;
}
@media (max-width: 1250px) {
  .signinSec .signInBox {
    padding: 0 40px 0 40px;
  }
}
@media (max-width: 1100px) {
  .signinSec .signInBox .title h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .signinSec .LogoBox figure img {
    width: 100%;
    padding: 0 40px;
  }
}
@media (max-width: 768px) {
  .signinSec .img-Dv {
    display: none;
  }
  .signinSec .signInBox {
    padding: 60px 40px 60px 40px;
  }
  .signinSec .signInBox .privacyDv {
    position: unset;
    padding-top: 30px;
  }
  .signinSec .container-fluid {
    padding: 0 55px;
  }
}
@media (max-width: 575px) {
  .signinSec .container-fluid {
    padding: 0 30px;
  }
  .signinSec .signInBox {
    padding: 30px 10px 30px 10px;
  }
  .signinSec .signInBox .title h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .signinSec .signInBox .taglines h4 {
    font-size: 15px;
    line-height: 22px;
  }
  .signinSec .signInBox .taglines h4 span.big {
    font-size: 25px;
    line-height: 35px;
  }
  .signinSec .signInBox .privacyDv p {
    font-size: 14px;
    line-height: 24px;
  }
  .signinSec .signInBox .actionsLink {
    display: block;
    text-align: center;
  }
  .signinSec .signInBox .actionsLink label {
    font-size: 13px;
    margin-bottom: 10px;
  }
  .signinSec .signInBox .actionsLink .forgotDv a {
    font-size: 13px;
  }
}
/* Signin Css End Here */
