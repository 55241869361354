/* Truck Css Start Here */
.who-we-are {
  padding: 60px 0px;
  position: relative;
}

.who-we-are .heading_wrapper {
  padding-bottom: 80px;
  text-align: center;
}

.who-we-are .content-wrapper {
  padding-top: 40px;
}

.who-we-are img.we1 {
  bottom: -40%;
}

.who-we-are img.we2 {
  bottom: 40%;
}

.truck_point {
  padding: 0px 0 80px;
}

.truck_point .text_with_icon {
  display: flex;
  align-items: flex-start;
  margin-top: 90px;
}

.truck_point .text_with_icon .text {
  padding-left: 15px;
}

.truck_point .text_with_icon .icon_box img {
  max-width: 70px;
}

.truck_point .text_with_icon .icon_box {
  width: 140px;
  margin-top: -10px;
}

.truck_point .text_with_icon .text h2 {
  font-size: 33px;
  color: #165eb9;
  font-family: "poppins";
}

.truck_point .text_with_icon p {
  color: #7b7676;
  font-size: 13px;
  padding-right: 30px;
}

/* Truck Css End Here */
/* zaam css starts */
.TruckPage .trk_imggg{
  height: 335px!important;
  width: 83%!important;
  margin: auto!important;
  object-fit: cover!important;
  margin-top: 25px!important;
}