/* Demand Css Start Here */
.who-we-are .heading_wrapper {
  text-align: center;
  padding-bottom: 100px;
}
.who-we-are img.we2 {
  position: absolute;
  bottom: 50%;
  width: 15%;
}
.blue_sec .img-wrapper::after {
  background: #4e85ca;
}
.crunchy_sec.blue_sec img.we2 {
  right: unset;
  left: -50px;
  bottom: 10%;
}
/* Demand Css End Here */
