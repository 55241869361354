@media only screen and (max-width: 992px) {
  .HomeContent .slick-dots {
    display: none;
  }

  .home_top_slider .Slider_top_custom_arrows-next {
    display: none;
  }

  .home_top_slider .Slider_top_custom_arrows {
    display: none;
  }

  .HomeContent .banner-form-wrapper h2 {
    margin-top: 1rem;
  }

  .HomeContent .banner {
    padding-top: 40px;
    padding-bottom: 80px;
  }

  .HomeContent .mt-rezz-evetnone {
    margin-top: 14px;
  }

  .HomeContent .finish-rezz-evetnone {
    margin-top: 14px;
  }
  .HomeContent .evu {
    margin-top: 0rem;
  }

  .HomeContent .mt-rez-prize {
    margin-top: 1rem;
  }

  .HomeContent .who-we-are {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .HomeContent .who-we-are .row:nth-child(even) h2 {
    padding-right: 0px !important;
  }

  .HomeContent .who-we-are .col-lg-5 {
    margin-top: 2rem;
  }

  .HomeContent .cleanliness {
    padding: 10px 0;
    background-color: #ee97ac;
  }

  .HomeContent .connect .main-content-wrapper {
    margin-top: 2rem;
  }

  .HomeContent .mb-res-testihead {
    margin-bottom: 1rem !important;
  }

  .HomeContent .connect .col-lg-12 {
    margin-bottom: 0rem !important;
  }

  .HomeContent .coming {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #2498e2;
  }

  .HomeContent .connect .main-content-wrapper h3 {
    font-size: 26px;
  }

  .HomeContent .connect .main-content-wrapper h2 {
    font-size: 22px;
  }

  .HomeContent .connect .content-wrapper h2 {
    font-size: 22px;
    text-align: center;
  }

  .HomeContent .coming h4 {
    text-align: center;
  }

  .HomeContent .coming h1 {
    font-size: 36px;
    text-align: center;
  }

  .HomeContent .fnt_head_sliderz_top {
    font-size: 28px !important;
    text-align: center;
  }

  .HomeContent .fnt_head_sliderz_top_two {
    font-size: 28px !important;
    text-align: center;
  }

  .HomeContent .banner-content-wrapper {
    text-align: center;
  }

  .HomeContent .flx-center-home_top {
    justify-content: center;
  }

  .HomeContent .banner-content-wrapper p {
    padding-right: 0rem !important;
  }

  .HomeContent .banner-form-wrapper h2 {
    font-size: 22px;
  }

  .HomeContent .cleanliness .content-wrapper h2 {
    font-size: 28px;
  }

  .HomeContent .coming a img {
    margin-bottom: 1rem;
  }

  .HomeContent .mb-res-testihead {
    font-size: 22px;
  }

  .HomeContent .testimonials {
    padding-bottom: 30px;
  }

  .HomeContent .testimonials h3 {
    font-size: 26px;
  }

  .HomeContent .testimonials .testimonails-item h6 {
    font-size: 14px;
  }

  .HomeContent .testimonials .testimonails-item span {
    font-size: 14px;
  }

  .HomeContent .testimonails-item li img {
    max-width: 45px;
    max-height: 45px;
  }

  .HomeContent .testimonails-item p {
    font-size: 11px;
  }

  .HomeContent .banner-content-wrapper .red_font {
    font-size: 20px !important;
  }

  .HomeContent .center_truckclean_responsv {
    text-align: center;
  }

  .btn {
    font-size: 12px;
  }

  .AboutPage .we1 {
    display: none;
  }

  .AboutPage .who-we-are h2 {
    font-size: 22px !important;
  }

  .AboutPage .location_sec .heading_wrapper h3 {
    font-size: 28px !important;
  }

  .AboutPage .location_sec .heading_wrapper h2 {
    font-size: 24px !important;
  }

  .AboutPage .location_sec .location_dv .content ul li {
    font-size: 11px;
  }

  .AboutPage .location_sec .location_dv .content ul li a {
    font-size: 11px;
  }

  .MenuPage .menu_sec .btn {
    padding: 4px 10px;
  }

  .GalleryPage .gallery_sec .heading_wrapper h3 {
    font-size: 28px;
  }

  .GalleryPage .gallery_sec .heading_wrapper h2 {
    font-size: 26px;
  }

  .ContactPage .contact_info .heading_wrapper h3 {
    font-size: 28px;
  }

  .ContactPage .contact_info .heading_wrapper h2 {
    font-size: 24px;
  }

  .ContactPage .contact_info .info_box {
    margin: 2rem 0;
  }

  .ContactPage .contact_info {
    padding-top: 0px;
    padding-bottom: 20px;
  }

  .ContactPage .contact_info .heading_wrapper {
    padding-bottom: 40px;
  }

  .ContactPage .contact_sec {
    padding: 25px 0;
  }

  .ContactPage .contact_sec .contact_form .heading_wrapper h3 {
    font-size: 34px;
    padding-bottom: 0px;
  }

  .ContactPage .contact_sec .contact_form .heading_wrapper {
    padding-bottom: 0;
  }

  .TruckPage .who-we-are h2 {
    font-size: 22px;
  }

  .TruckPage .who-we-are {
    padding: 20px 0;
    padding-bottom: 0;
  }

  .TruckPage .truck_point .text_with_icon {
    margin-top: 30px;
  }

  .TruckPage .truck_point .text_with_icon .text h2 {
    font-size: 20px;
  }

  .TruckPage .truck_point .text_with_icon .icon_box img {
    width: 60px;
    object-fit: cover;
    /* height: 80px; */
  }

  .BookEvent_Page .price_evl_ress {
    margin-top: 0.5rem;
  }

  .BookEvent_Page .book-event {
    padding-top: 10px;
    padding-bottom: 0;
  }

  .BookEvent_Page .mt-bhotzada {
    text-align-last: center;
    margin-top: 2rem;
  }

  .BookEvent_Page .book-event .event_img {
    margin-right: 0;
  }

  .BookEvent_Page .lambi_line {
    display: none;
  }

  .BookEvent_Page .banner-form-content-wrapper h2 {
    font-size: 22px;
  }

  /* .BookEvent_Page .banner-form-wrapper .order-summary ul li{
        flex-direction: column;
        list-style: circle;
    } */

  .BookEvent_Page .banner-form-content-wrapper h5 {
    font-size: 18px;
  }

  .BookEvent_Page .testimonials {
    padding-top: 0px !important;
    padding-bottom: 30px !important;
  }

  .BookEvent_Page .testimonials img.sandwish {
    display: none;
  }

  .BookEvent_Page .btn.next-step.frst {
    font-size: 13px;
    padding: 12.5px;
  }

  .TermsAndCondition_Page .privacy_sec .heading_wrapper h2 {
    font-size: 24px;
  }

  .TermsAndCondition_Page .privacy_sec {
    padding: 20px 0;
  }

  .enter_email .emailBox .heading_wrapper h2 {
    font-size: 28px;
  }

  .enter_email .emailBox .heading_wrapper h4 {
    font-size: 20px;
  }

  .ContactPage .contact_sec .contact_form {
    padding-left: 0px !important;
  }

  .universal_banner .page-head h2 {
    font-size: 26px !important;
    margin-top: 0rem;
  }

  .img_res_msall {
    width: 140px;
  }

  .universal_banner .banner_img img {
    height: 280px;
    object-fit: cover;
  }

  .AboutPage .pb-rez-noo {
    padding-bottom: 0 !important;
  }

  .AboutPage .who-we-are .content-wrapper {
    padding-top: 0px !important;
  }

  .AboutPage .testizaam .all_locations {
    padding-top: 20px !important;
  }

  .AboutPage .location_sec {
    padding: 20px 0;
  }

  .profile .profile-img-tab-wrapper,
  .profile .profile-img-tab-wrapper .img-content-wrapper {
    flex-direction: column;
  }

  .profile .profile-img-tab-wrapper .img-content-wrapper .profile-name-content {
    margin-left: 0;
  }

  img.we1 {
    display: none;
  }

  .Congrats_Page .congrats-all {
    width: 95% !important;
  }
}

@media (max-width: 575px) {
  .HomeContent .banner {
    padding-top: 40px;
    padding-bottom: 130px;
    background-size: cover;
    background-position: right;
  }

  .form_ghybb {
    display: none;
  }
}
