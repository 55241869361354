/* About Us Css Start Here */
.who-we-are .wrap-blur::after {
  display: none;
}

.who-we-are img.we2 {
  bottom: 15%;
}

.crunchy_sec {
  background-image: url("../Images/zigzag.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 280px 0 0;
}

.crunchy_sec .heading_wrapper {
  text-align: center;
  padding-bottom: 70px;
}

.crunchy_sec .button-group {
  margin-top: 60px;
}

.location_sec {
  padding: 60px 0;
}

.location_sec .heading_wrapper {
  text-align: center;
  padding-bottom: 30px;
}

.location_sec .heading_wrapper h3 {
  color: #fc9eb2;
  font-size: 65px;
}

.location_sec .heading_wrapper h2 {
  color: #165eb8;
  font-size: 48px;
}

.AboutPage .location_sec .heading_wrapper h2 {
  color: #165eb8;
  font-size: 36px;
}

.location_sec .location_box .form-control {
  background: #fca4b7;
  color: #fff;
  border: 0;
  font-size: 16px;
  font-family: "Poppins";
  height: 48px !important;
  padding: 0 25px;
}

.location_sec .location_box {
  margin-bottom: 70px;
}

.AboutPage .location_sec .location_dv {
  max-width: 600px;
  margin: auto;
}

.location_sec .location_dv {
  background: #fca4b7;
  padding: 20px 30px;
  margin: 0 10px;
  border-radius: 10px;
  transition: 0.6s;
}

.location_sec .location_dv .location_img img {
  margin: 0px auto 0;
}

.location_sec .location_dv .location_img {
  margin-top: -95px;
}

.location_sec .slick-list.draggable {
  padding-top: 80px;
}

.location_sec .location_dv:nth-child(even) {
  background: #165eb9;
}

.location_sec .location_dv:hover:nth-child(even) {
  background: #fca4b7;
}

.location_sec .location_dv:hover:nth-child(odd) {
  background: #165eb9;
}

.location_sec .location_dv .content {
  text-align: center;
}

.location_sec .location_dv .content h4 {
  font-size: 18px;
  color: #33000b;
  font-family: "Poppins";
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 15px;
  transition: 0.6s;
}

.location_sec .location_dv .content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.location_sec .location_dv .content ul li {
  color: #33000b;
  font-size: 15px;
  margin: 10px 0;
  transition: 0.6s;
  font-weight: 900;
}

.location_sec .location_dv .content ul li a {
  color: #33000b;
  font-size: 15px;
  font-weight: 900;
  transition: 0.6s;
  text-decoration: none;
}

.location_sec .location_dv:nth-child(even) .content h4 {
  color: #fff;
}

.location_sec .location_dv:nth-child(even) .content ul li {
  color: #ffffffc2;
}

.location_sec .location_dv:nth-child(even) .content ul li a {
  color: #ffffffc2;
}

.location_sec .location_dv:hover:nth-child(even) .content h4 {
  color: #33000b;
}

.location_sec .location_dv:hover:nth-child(even) .content ul li {
  color: #33000b;
}

.location_sec .location_dv:hover:nth-child(even) .content ul li a {
  color: #33000b;
}

.location_sec .location_dv:hover:nth-child(odd) .content h4 {
  color: #fff;
}

.location_sec .location_dv:hover:nth-child(odd) .content ul li {
  color: #ffffffc2;
}

.location_sec .img_location {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  object-fit: cover;
}

.location_sec .location_dv:hover:nth-child(odd) .content ul li a {
  color: #ffffffc2;
}

.location_sec ul.slick-dots {
  bottom: -20%;
}

.location_sec ul.slick-dots li {
  background: #c2c2c2;
  width: 60px;
  height: 4px;
}

.location_sec ul.slick-dots li button:after {
  width: 120px;
  background: #000;
}

.location_sec ul.slick-dots li.slick-active {
  background: #165eb9;
}

.AboutPage .who-we-are .h-settt {
  height: 335px;
  width: 83%;
  margin: auto;
  object-fit: cover;
  margin-top: 25px;
}

.AboutPage .pt-abt-cuz {
  padding-top: 10rem;
}

.twitter-tweet {
  margin: auto;
}

.tweet_secc {
  width: 100% !important;
}

/* About Us Css End Here */
