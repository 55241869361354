/* zaam css */
.view_profile_page .big_blue_div_profe {
  background-color: #009de7;
  width: 100%;
  height: 210px;
}

.butnnnn {
  background: #1297dc;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  position: relative;
  left: -20px;
  top: 18px;
  border: 1px solid;
}

.inpt-img-upld {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}

.img-prf-rnddd{
  border-radius: 50%;
}

/* header starts here */
/* header#header {
  background: #1297dc;
} */
/* header ends here */
/* profile starts here */
.profile {
  padding-top: 100px;
  padding-bottom: 100px;
}

/* profile tabs starts here */
.profile .profile-img-tab-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile .profile-img-tab-wrapper .img-content-wrapper {
  display: flex;
  align-items: center;
}

.profile .profile-img-tab-wrapper .img-content-wrapper button {
  background: #1297dc;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  position: absolute;
  left: 33%;
  top: 50%;
  border: 1px solid;
}

.profile .profile-img-tab-wrapper .img-content-wrapper {
  position: relative;
}

.profile .profile-img-tab-wrapper .img-content-wrapper img {
  height: 102px;
  width: 102px;
  object-fit: cover;
}

.profile .profile-img-tab-wrapper .img-content-wrapper .profile-name-content {
  margin-left: 45px;
}

.profile .profile-img-tab-wrapper .img-content-wrapper .profile-name-content h4 {
  color: #1297dc;
  font-family: "Helvetica Rounded LT Std";
  font-size: 28px;
}

.profile .profile-img-tab-wrapper .img-content-wrapper .profile-name-content h5 {
  color: #888888;
  font-size: 22px;
}

.profile .profile-button-wrappper button.edit-profile {
  color: #1297dc;
  background: transparent;
  border: 2px solid #1297dc;
}

.profile .profile-form-wrapper input.form-control {
  background: transparent;
  border: none;
  border-bottom: 2px solid #0275d8;
  border-radius: 0px;
}

.profile .profile-form-wrapper {
  padding-top: 40px;
}

.profile .profile-form-wrapper input.form-control::placeholder {
  color: #009de7;
  font-size: 20px;
  font-family: "Helvetica Rounded LT Std";
  font-weight: 700;
}

.profile .profile-form-wrapper label {
  color: #242424;
  font-family: "Helvetica Rounded LT Std";
  font-size: 20px;
  margin: 24px 0px;
}

.profile-tabs-wrapper .profile-heading-wrapper h4 {
  font-family: "Helvetica Rounded LT Std";
  font-size: 30px;
  margin-bottom: 37px;
}

.profile-tabs-wrapper .profile-heading-wrapper {
  margin-bottom: 30px;
}

.profile-tabs-wrapper .profile-heading-wrapper {
  margin-bottom: 30px;
}

.nav-pills .nav-link.active {
  background: transparent;
  color: #1297dc !important;
  font-size: 22px;
  font-weight: 700;
  font-family: "Helvetica Rounded LT Std";
  border-left: 3px solid;
  border-radius: 0px;
}

.profile .profile-tabs-wrapper .nav-pills .nav-link {
  font-family: "Helvetica Rounded LT Std";
  color: #242424;
  font-size: 22px;
  margin-bottom: 20px;
}

.profile .profile-edit-form-wrapper {
  padding-top: 40px;
}

.profile .profile-edit-form-wrapper label {
  color: #242424;
  font-family: "Helvetica Rounded LT Std";
  font-size: 20px;
  margin: 14px 0px;
}

.profile .profile-edit-form-wrapper input {
  border: 1px solid #9e9e9e;
  padding: 15px;
  border-radius: 10px;
}

.profile .profile-edit-form-wrapper input::placeholder {
  color: #bfbfbf;
  font-family: "Helvetica Rounded LT Std";
  font-size: 20px;
}

.profile .profile-edit-form-wrapper .btn {
  background: #1297dc;
}

/* profile tabs ends here */

/* Second Tab Css Start Here */
.profile .security_tab .form-group {
  margin-top: 20px;
}

.profile .security_tab .form-group label {
  color: #242424;
  font-family: "Helvetica Rounded LT Std";
  font-size: 20px;
}

.profile .security_tab .form-group .form-control {
  border: 1px solid #9e9e9e;
}

.profile .security_tab .form-group .form-control {
  color: #bfbfbf;
}

.profile .security_tab .form-group button.btn {
  background: #1297dc;
  cursor: pointer;
}

/* Second Tab Css End Here */

/* Third Tab Css Start Here */
.profile ul.pagination {
  align-items: center;
  justify-content: end;
  margin-top: 20px;
}

.profile ul.pagination li a {
  border: 1px solid #dcdcdc;
  border-radius: 7px;
  width: 40px;
  display: block;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #bebebe;
  text-decoration: none;
  margin: 0 5px;
}

.profile ul.pagination li a.active {
  background: #0094e7;
  color: #fff;
  border-color: #0094e7;
}

.profile ul.pagination li a.more {
  border: 0;
  width: auto;
  height: auto;
  margin: 0;
}

.profile ul.pagination li a.btn {
  color: #fff;
  background: #0094e7;
  border: 0;
  padding: 3px 40px;
  height: auto;
  width: auto;
  border-radius: 5px;
  font-size: 14px;
}

.profile .booking_tab table.table thead {
  background: transparent;
}

.profile .booking_tab table.table thead th {
  border: 0;
  font-size: 20px;
  color: #242424;
  font-family: "Helvetica Rounded LT Std";
  text-align: center;
}

.profile .booking_tab table.table tbody td {
  border: 0;
  font-family: "Poppins";
  color: #6b6b6b;
  font-size: 15px;
  font-weight: 600;
  vertical-align: middle;
}

.profile .booking_tab table.table tbody tr:nth-child(odd) {
  background: #f2f2f2;
}

.profile .booking_tab table.table thead th.date {
  text-align: left;
}

.profile .booking_tab table.table tbody td a {
  color: #6b6b6b;
  text-decoration: underline;
}

.profile .booking_tab table.table tbody tr:nth-child(even) {
  background: #fbfbfb;
}

.profile .booking_tab table.table tbody tr {
  height: 53px;
  border-bottom: 5px solid #fff;
}

/* Third Tab Css End Here */

/* profile ends here */