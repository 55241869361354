/* zaam css starts her? */
.BookEvent_Page .lambi_line {
  border-bottom: 2px solid lightgrey;
  position: absolute;
  top: 45%;
  width: 75%;
  z-index: -1;
}

.BookEvent_Page .maindiv_lambiline {
  padding-top: 2rem;
  position: relative;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-bottom: 2rem;
}

.BookEvent_Page .flx_stepsboxes {
  display: flex;
  justify-content: space-between;
}

.BookEvent_Page .bx-active {
  border-radius: 50%;
  border: 1px solid #306cbc;
  color: white;
  display: inline-block;
  padding: 0.5rem 1rem;
  font-weight: 600;
  background: #306cbc;
  margin-bottom: 0.5rem;
}

.BookEvent_Page .step1 {
  font-weight: 600;
  color: #306cbc;
}

.BookEvent_Page .bx-noactive {
  border-radius: 50%;
  border: 1px solid #306cbc;
  color: #306cbc;
  display: inline-block;
  padding: 0.5rem 1rem;
  font-weight: 600;
  background: #fff;
  margin-bottom: 0.5rem;
}

.BookEvent_Page .mt-bhotzada {
  margin-top: 5rem;
}

/* .wizard .banner-form-wrapper .label{
  text-transform: capitalize!important;
} */

/* zaam css ends her? */

/* Book Event Css Start Here */
.BookEvent_Page .book-event {
  padding: 100px 0 60px;
  position: relative;
}

.BookEvent_Page .book-event img.we1 {
  position: absolute;
  left: -80px;
  bottom: 0%;
  width: 15%;
}

.BookEvent_Page .book-event img.we2 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10%;
}

.BookEvent_Page .book-event .event_img {
  position: relative;
  margin-right: 50px;
}

.BookEvent_Page .book-event .event_img:before {
  content: "";
  background: #fbd1d6;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  z-index: -1;
  transform: rotate(5deg);
  transition: 0.6s;
}

.BookEvent_Page .wizard .nav-tabs {
  position: relative;
  margin-bottom: 0;
  border-bottom-color: transparent;
  justify-content: center;
}

.BookEvent_Page .wizard > div.wizard-inner {
  position: relative;
  margin-top: -120px;
  text-align: center;
  padding-bottom: 100px;
}

.BookEvent_Page .wizard > div.wizard-inner .hidden-tab {
  background: transparent;
  width: 100%;
  height: 60px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
}

.BookEvent_Page .connecting-line {
  height: 2px;
  background: #e0e0e0;
  position: absolute;
  width: 65%;
  margin: 0 auto;
  left: 10px;
  right: 0;
  top: 15px;
  z-index: 1;
}

.wizard .nav-tabs > li.active > a,
.wizard .nav-tabs > li.active > a:hover,
.wizard .nav-tabs > li.active > a:focus {
  color: #555555;
  cursor: default;
  border: 0;
  border-bottom-color: transparent;
}

.BookEvent_Page span.round-tab {
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: 14px;
  color: #165eb9;
  font-weight: 500;
  border: 1px solid #165eb9;
}

.BookEvent_Page span.round-tab i {
  color: #555555;
}

.BookEvent_Page .wizard li.active span.round-tab {
  background: #165eb9;
  color: #fff;
  border-color: #165eb9;
}

.BookEvent_Page .wizard li.active span.round-tab i {
  color: #5bc0de;
}

.BookEvent_Page .wizard .nav-tabs > li.active > a i {
  color: #165eb9;
}

.BookEvent_Page .wizard .nav-tabs > li {
  width: 100%;
}

.BookEvent_Page .wizard li:after {
  content: " ";
  position: absolute;
  left: 46%;
  opacity: 0;
  margin: 0 auto;
  bottom: 0px;
  border: 5px solid transparent;
  border-bottom-color: red;
  transition: 0.1s ease-in-out;
}

.BookEvent_Page .wizard .nav-tabs > li a {
  width: 30px;
  height: 30px;
  margin: 20px auto;
  border-radius: 100%;
  padding: 0;
  background-color: transparent;
  position: relative;
  top: 0;
}

.BookEvent_Page .wizard .nav-tabs > li a i {
  position: absolute;
  bottom: -55px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  left: 50%;
  /* transform: translate(-50%, -50%); */
  font-size: 12px;
  font-weight: 700;
  color: #000;
  color: #165eb9;
}

.BookEvent_Page .wizard .nav-tabs > li a:hover {
  background: transparent;
}

.BookEvent_Page .wizard .tab-pane {
  position: relative;
  padding-top: 0px;
}

.BookEvent_Page .wizard h3 {
  margin-top: 0;
}

.BookEvent_Page .step-head {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 20px;
}

.BookEvent_Page .term-check {
  font-size: 14px;
  font-weight: 400;
}

.BookEvent_Page .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 40px;
  margin-bottom: 0;
}

.BookEvent_Page .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  opacity: 0;
}

.BookEvent_Page .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.BookEvent_Page .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 38px;
  padding: 0.375rem 0.75rem;
  line-height: 2;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.BookEvent_Page .footer-link {
  margin-top: 30px;
}

.BookEvent_Page .list-content {
  margin-bottom: 10px;
}

.BookEvent_Page .list-content a {
  padding: 10px 15px;
  width: 100%;
  display: inline-block;
  background-color: #f5f5f5;
  position: relative;
  color: #565656;
  font-weight: 400;
  border-radius: 4px;
}

.BookEvent_Page .list-content a[aria-expanded="true"] i {
  transform: rotate(180deg);
}

.BookEvent_Page .list-content a i {
  text-align: right;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: 0.5s;
}

.BookEvent_Page .form-control[disabled],
.BookEvent_Page .form-control[readonly],
.BookEvent_Page fieldset[disabled] .form-control {
  background-color: #fdfdfd;
}

.BookEvent_Page .list-box {
  padding: 10px;
}

.signup-logo-header .logo_area {
  width: 200px;
}

.signup-logo-header .nav > li {
  padding: 0;
}

.signup-logo-header .header-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*-----------custom-checkbox-----------*/
/*----------Custom-Checkbox---------*/
.BookEvent_Page input[type="checkbox"] {
  position: relative;
  display: inline-block;
  margin-right: 5px;
}

.BookEvent_Page input[type="checkbox"]::before,
.BookEvent_Page input[type="checkbox"]::after {
  position: absolute;
  content: "";
  display: inline-block;
}

.BookEvent_Page input[type="checkbox"]::before {
  height: 16px;
  width: 16px;
  border: 1px solid #999;
  left: 0px;
  top: 0px;
  background-color: #fff;
  border-radius: 2px;
}

.BookEvent_Page input[type="checkbox"]::after {
  height: 5px;
  width: 9px;
  left: 4px;
  top: 4px;
}

.BookEvent_Page input[type="checkbox"]:checked::after {
  content: "";
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(-45deg);
}

.BookEvent_Page input[type="checkbox"]:checked::before {
  background-color: #18ba60;
  border-color: #18ba60;
}

@media (max-width: 767px) {
  .BookEvent_Page .sign-content h3 {
    font-size: 40px;
  }

  .BookEvent_Page .wizard .nav-tabs > li a i {
    display: none;
  }

  .BookEvent_Page .signup-logo-header .navbar-toggle {
    margin: 0;
    margin-top: 8px;
  }

  .BookEvent_Page .signup-logo-header .logo_area {
    margin-top: 0;
  }

  .BookEvent_Page .signup-logo-header .header-flex {
    display: block;
  }
}

/* First Step Css Start Here */
.BookEvent_Page .banner-form-content-wrapper h3 {
  color: #fca4b7;
  font-size: 63px;
}

.BookEvent_Page .banner-form-content-wrapper h2 {
  color: #165eb9;
  font-size: 41px;
  text-transform: uppercase;
  font-weight: 800;
}

.BookEvent_Page .banner-form-content-wrapper h5 {
  color: #fca4b7;
  font-size: 25px;
  margin-top: 16px;
}

.BookEvent_Page .banner-form-wrapper .form-group .form-control {
  border: 1px solid #c2c2c2 !important;
  font-size: 15px !important;
  text-transform: none;
  color: #c4c4c4 !important;
  letter-spacing: 1px;
  height: 60px !important;
}

.BookEvent_Page .banner-form-wrapper label {
  color: #c4c4c4;
  font-size: 15px;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.BookEvent_Page .banner-form-wrapper input.form-control.evu {
  font-size: 28px !important;
  color: #165eb9 !important;
  text-align: center;
}

.BookEvent_Page .banner-form-wrapper ul.list-inline button.frst {
  background-color: #fc9eb2 !important;
  font-size: 17px;
  color: #fff;
  text-decoration: none;
  font-family: "Poppins";
  font-weight: 600;
  padding: 11px 25px;
  border-radius: 30px;
}

.BookEvent_Page .btn.next-step.frst {
  background-color: #165eb9 !important;
  width: 100%;
  padding: 16px 0;
  font-size: 20px;
  cursor: pointer;
  margin-top: 10px;
}

/* First Step Css End Here */

/* Second Tab Css Start Here */
.BookEvent_Page .banner-form-wrapper.scnd-step p {
  margin: 0;
  font-size: 15px;
  color: #707070;
  font-weight: 500;
  padding: 15px 0;
}

.BookEvent_Page .banner-form-wrapper .form-group textarea.form-control {
  height: 100px !important;
  border-radius: 8px;
  resize: none;
}

.BookEvent_Page
  .banner-form-wrapper
  .form-group
  textarea.form-control::placeholder {
  color: #c4c4c4;
  font-family: "Helvetica Rounded LT Std";
}

.banner-form-wrapper input.form-control::placeholder {
  color: #c4c4c4;
  font-family: "Helvetica Rounded LT Std";
  text-transform: none !important;
}

button.default-btn.btn.scnd.next-step {
  background: #165eb9 !important;
  cursor: pointer;
}

/* Second Tab Css End Here */

/* Third Tab Css Start Here */
.BookEvent_Page ul.payments_cards {
  padding: 0;
  margin: 0;
  list-style: none;
}

.BookEvent_Page ul.payments_cards li {
  display: inline-block;
}

.BookEvent_Page ul.payments_cards li label.container {
  display: unset;
}

.BookEvent_Page ul.payments_cards li label.container input {
  opacity: 0;
}

.BookEvent_Page ul.payments_cards li label.container span.checkmark img {
  width: 60px;
  height: 38px;
  padding: 4px 4px;
}

.BookEvent_Page
  ul.payments_cards
  li
  label.container
  input:checked
  ~ span.checkmark
  img {
  border: 2px solid #f35f63;
}

.BookEvent_Page .wizard #step3 .banner-form-content-wrapper h4 {
  font-size: 35px;
  color: #165eb9;
  font-family: "Helvetica Rounded LT Std";
}

.BookEvent_Page .wizard #step3 .form-group label {
  font-size: 17px;
  color: #165eb9;
  text-transform: capitalize;
  text-shadow: 0 0 #165eb9;
}

.BookEvent_Page .wizard #step3 .form-group {
  margin: 35px 0 20px;
}

.BookEvent_Page .banner-form-wrapper .order-summary {
  background: #f6f6f6;
  padding: 30px 30px 5px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.BookEvent_Page .banner-form-wrapper .order-summary .head {
  padding-bottom: 10px;
}

.BookEvent_Page .banner-form-wrapper .order-summary .head h4 {
  font-size: 20px;
  color: #6c6c6c;
  font-family: "Helvetica Rounded LT Std";
}

.BookEvent_Page .banner-form-wrapper .order-summary ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.BookEvent_Page .banner-form-wrapper .order-summary ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.BookEvent_Page .banner-form-wrapper .order-summary ul li span {
  font-size: 14px;
  color: #b2b2b2;
  font-family: "Poppins";
  font-weight: 500;
}

.BookEvent_Page .banner-form-wrapper .order-summary ul li .property {
  min-width: 155px !important;
}

.BookEvent_Page .banner-form-wrapper .order-summary li.total {
  border-top: 1px solid #b2b2b2;
  padding-top: 10px;
  margin-top: 10px;
}

.BookEvent_Page button.default-btn.btn.next-step.lst {
  background: #165eb9;
  cursor: pointer;
}

/* Third Tab Css End Here */
/* Book Event Css End Here */

/* Testimonials Sec Start Here */
/* .testimonials {
  padding: 120px 0px 250px;
  background-image: url("../Images/testi-back.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.testimonials h2 {
  color: #165eb9;
  font-size: 48px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.testimonials ul.slick-dots {
  left: -74%;
} */
/* Testimonials Sec End Here */

/* Gallery Css Start Here */
/* .gallery_sec {
  padding: 0px 0 60px;
}
.gallery_sec .heading_wrapper {
  padding-bottom: 60px;
  text-align: center;
}
.gallery_sec .heading_wrapper h3 {
  font-size: 65px;
  color: #fc9eb2;
}
.gallery_sec .heading_wrapper h2 {
  color: #165eb9;
  text-transform: uppercase;
}
.gallery_sec .slick-list.draggable {
  padding-top: 30px;
  padding-bottom: 30px;
}
.gallery_sec .gallery_box {
  margin: 0 20px;
  position: relative;
}
.gallery_sec .gallery_box:before {
  content: "";
  background: #fbd1d6;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  z-index: -1;
  transform: rotate(5deg);
  transition: 0.6s;
  top: -10px;
}
.gallery_sec .gallery_box:nth-child(even):before {
  background: #4e85ca;
}
.gallery_sec .gallery_box:hover:before {
  transform: rotate(-5deg);
}
.gallery_sec .gallery_box img {
  border-radius: 10px;
}
.gallery_sec .button-group {
  text-align: center;
  margin-top: 50px;
}
.gallery_sec .button-group a.btn {
  background: #165eb9;
  padding: 15px 30px;
  color: #fff;
}
.gallery_sec ul.slick-dots li {
  background: #c2c2c2;
  width: 55px;
  height: 4px;
}
.gallery_sec ul.slick-dots li button:before {
  font-size: 0;
}
.gallery_sec ul.slick-dots li.slick-active {
  background: #165eb9;
} */
/* Gallery Css End Here */
.continue-btn-below-text p {
  font-size: 11px;
  font-family: 'Poppins';
  margin: 0;
}