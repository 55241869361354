/* Faqs Css Start Here */
.faqs {
  position: relative;
}

img.we1 {
  position: absolute;
  bottom: 0;
  left: -6%;
  animation: up-down linear 4s infinite;
  z-index: 9;
}

img.we2 {
  position: absolute;
  right: 0px;
  top: 10%;
  z-index: -1;
  animation: up-down linear 4s infinite;
  z-index: 9;
}

.faqs {
  padding: 60px 0;
}

.faqs .heading_wrapper {
  text-align: center;
  padding-bottom: 30px;
}

.faqs .heading_wrapper h3 {
  color: #fc9eb2;
}

.faqs .heading_wrapper h2 {
  color: #165eb9;
  text-transform: uppercase;
}

.faqs .heading_wrapper p {
  color: #7b7676;
  padding-top: 15px;
}

.faqs .card {
  border: 0;
  border-radius: 0;
  margin-bottom: 20px;
}

.faqs .card .card-header {
  background: transparent;
  padding: 0;
  border: 0;
  box-shadow: 0px 0px 10px #ddd;
  border-radius: 8px;
}

.faqs .card .card-header button.btn.btn-link {
  background: #fca4b7;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  box-shadow: none;
  outline: none;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  padding: 15px 30px;
  border-radius: 8px;
}

.faqs .card .card-header button.btn.btn-link h5 {
  margin: 0;
  font-family: "poppins";
  font-size: 14px;
  font-weight: 600;
}

.faqs .card .card-body {
  background: #ffe9ee;
  padding: 30px 30px;
  font-size: 14px;
  color: #7b7676;
  line-height: 22px;
}

/* Faqs Css End Here */

/* zaam faq starts here */
.faqs .accordianSummary {
  background: #fca4b7;
  color: white;
  border-radius: 15px;
}

.faqs .accordianSummary p {
  margin-bottom: 0;
  font-weight: 600;
}

.faqs .accordian {
  box-shadow: unset;
  background-color: #ffe9ee;
  border-radius: 15px 15px 0 0 !important;
}

.faqs .accordionDetails {
  padding: 30px 30px;
  font-size: 14px;
  color: #7b7676;
  line-height: 22px;
}


/* zaam faqs ends here */