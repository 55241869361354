/* Menu Css Start Here */
.menu_sec {
  padding: 60px 0;
  position: relative;
}

.menu_sec a {
  text-decoration: none;
}

.menu_sec .btn {
  background-color: #165eb9;
  color: white;
  padding: 11px 20px;
}

.gallery_sec .btn {
  background-color: #165eb9;
  color: white;
  padding: 11px 20px;
}


.menu_sec .product_img {
  position: relative;
}

.gallery_sec .col-lg-4:nth-child(even) .gallery_box:before {
  background: #2798db !important;
}

.menu_sec .description_manu *{
  color: #9f9f9f!important;
  font-size: 17px!important;
  font-family: "Poppins"!important;
  font-weight: 400!important;
}

.menu_sec .product_img:before {
  content: "";
  background: #fbd1d6;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  z-index: -1;
  transform: rotate(5deg);
  transition: 0.6s;
}

.menu_sec .product-detail .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu_sec .product-detail {
  padding-top: 20px;
}

.menu_sec .product-detail .name h4 {
  padding-right: 1rem;
  font-family: "Helvetica Rounded LT Std";
  color: #515458;
  font-size: 27px;
}

.product_box {
  margin-bottom: 100px;
}

.menu_sec .product-detail .name h4.price {
  border-left: 1px solid #515458;
  padding: 3px 0 0 30px;
}

.menu_sec .product-detail p {
  color: #9f9f9f;
  font-size: 17px;
}

.menu_sec .product_img img {
  height: 360px;
  width: 100%;
  object-fit: contain;
}

img.we1 {
  position: absolute;
  bottom: 15%;
  left: -70px;
  width: 200px;
}

img.we2 {
  position: absolute;
  right: 0;
  top: 35%;
  width: 200px;
}

ul.pagination {
  align-items: center;
  justify-content: end;
}

ul.pagination li a {
  border: 1px solid #dcdcdc;
  border-radius: 50%;
  width: 45px;
  display: block;
  height: 45px;
  text-align: center;
  line-height: 45px;
  color: #bebebe;
  text-decoration: none;
  margin: 0 7px;
}

ul.pagination li a.active {
  background: #165eb9;
  color: #fff;
  border-color: #165eb9;
}

ul.pagination li a.more {
  border: 0;
  width: auto;
  height: auto;
  margin: 0;
}

ul.pagination li a.btn {
  color: #fff;
  background: #165eb9;
  border: 0;
  padding: 3px 50px;
  height: auto;
  width: auto;
  border-radius: 50px;
}

/* Menu Css End Here */