/* Contact Css Start Here */
.contact_info {
  padding: 60px 0;
}

.contact_info .mappam {
  height: 500px;
  width: 100%;
}

.contact_info .heading_wrapper {
  text-align: center;
  padding-bottom: 100px;
}

.contact_info .heading_wrapper h3 {
  color: #fc9eb2;
  font-size: 65px;
}

.contact_info .heading_wrapper h2 {
  color: #165eb9;
  text-transform: uppercase;
}

.contact_info .info_box {
  text-align: center;
  background: #fca4b7;
  border-radius: 10px;
  padding: 20px 10px;
  transition: 0.6s;
  height: 185px;
}

.contact_info .info_box:hover {
  background: #165eb9;
}

.contact_info .info_box .icon_box {
  background: #ffe9ee;
  color: #fca4b7;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  line-height: 120px;
  margin: 0px auto;
  border: 1px solid #fca4b7;
  margin-top: -70px;
  transition: 0.6s;
}

.contact_info .info_box:hover {
  background: #165eb9;
}

.contact_info .info_box:hover .icon_box {
  background: #c2edff;
  border-color: #76aef6;
}

.contact_info .info_box .icon_box i {
  font-size: 30px;
  transition: 0.6s;
}

.contact_info .info_box:hover .icon_box i {
  color: #165eb9;
}

.contact_info .info_box h5 {
  font-size: 18px;
  color: #33000b;
  font-family: "Poppins";
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.6s;
}

.contact_info .info_box:hover h5 {
  color: #fff;
}

.contact_info .info_box ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.contact_info .info_box ul li {
  color: #000000fa;
  font-size: 14px;
  margin: 10px 0;
  transition: 0.6s;
}

.contact_info .info_box:hover ul li {
  color: #fff;
}

.contact_info .info_box .content {
  padding-top: 20px;
}

.contact_sec {
  padding: 60px 0;
}

.contact_sec iframe {
  border-radius: 10px;
}

.contact_sec .google_map {
  position: relative;
}

.contact_sec .google_map:before {
  content: "";
  background: #fbd1d6;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 10px;
  z-index: -1;
  transform: rotate(4deg);
}

.contact_sec .contact_form {
  padding-left: 20px;
}

.contact_sec .contact_form .heading_wrapper {
  padding-bottom: 40px;
}

.contact_sec .contact_form .heading_wrapper h3 {
  font-size: 70px;
  color: #fca4b7;
  line-height: 70px;
}

.contact_sec .contact_form .heading_wrapper h2 {
  color: #165eb9;
  text-transform: uppercase;
}

.contact_sec .contact_form .form-group .form-control {
  border: 1px solid #e2e2e2;
  background: #fff;
  height: 45px;
  border-radius: 5px;
  padding: 13px 20px;
  font-size: 12px;
  color: #888888;
  text-transform: uppercase;
  font-family: "Poppins";
  font-weight: 500;
}

.contact_sec .contact_form .form-group .form-control::placeholder {
  color: #888888;
  text-transform: uppercase;
  font-family: "Poppins";
  font-weight: 500;
}

.contact_sec .contact_form .form-group textarea {
  height: 100px !important;
  resize: none;
}

.contact_sec .contact_form .button-group {
  margin-top: 30px;
}

.contact_sec .contact_form .button-group .btn {
  background: #165eb9;
  padding: 12px 55px;
  cursor: pointer;
  border: 2px solid #165eb9;
}

.contact_sec .contact_form .button-group .btn:hover {
  box-shadow: 0px 0px 0px 2px #165eb9;
  border-color: #fff;
}

/* Contact Css End Here */