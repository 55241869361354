.HomeContent .testimonails-wrapper .slick-dots ul {
  margin: 0px !important;
  position: absolute !important;
  left: -54% !important;
  top: -338% !important;
}

.HomeContent .testimonials .slick-dots li button:before {
  font-family: "slick" !important;
  font-size: 9px !important;
  line-height: 17px !important;
  position: absolute !important;
  top: 4px !important;
  left: 0 !important;
  width: 16px !important;
  height: 16px !important;
  content: "•" !important;
  text-align: center !important;
  opacity: 1 !important;
  color: #165eb9 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.HomeContent .slick-dots {
  bottom: -147px !important;
  left: 10px !important;
}

.testizaam .all_locations {
  padding-top: 75px !important;
}

.paamm .slick-dots li {
  background: #c2c2c2;
  width: 60px;
  height: 4px;
}

.paamm .slick-dots li button {
  width: 100%;
  opacity: 0;
}

.paamm .slick-dots ul {
  position: relative;
  top: 34px;
  left: -1%;
}
.paamm .slick-dots .slick-active {
  background-color: #165eb9;
}

.img-testimonazzzzzzzz {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}
