/* font family cdn starts here */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.cdnfonts.com/css/billion-dreams");
@import url("https://fonts.cdnfonts.com/css/helvetica-rounded-lt-std");

/* @font-face {
  font-family: 'SnackerComic_PerosnalUseOnly';
  font-style: normal;
  font-weight: normal;
  src: local('SnackerComic_PerosnalUseOnly'), url('../Fonts/SnackerComic_PerosnalUseOnly.ttf') format('woff');
}

@font-face {
  font-family: 'BenjaminFranklin Beveled';
  font-style: normal;
  font-weight: normal;
  src: local('BenjaminFranklin Beveled'), url('../Fonts/BenjaminFranklin\ Beveled.ttf') format('woff');
} */

@font-face {
  font-family: 'NOSED___';
  font-style: normal;
  font-weight: normal;
  src: local('NOSED___'), url('../Fonts/NOSED___.TTF') format('woff');
}

@font-face {
  font-family: 'PermanentMarker-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('PermanentMarker-Regular'), url('../Fonts/PermanentMarker-Regular.ttf') format('woff');
}


@font-face {
  font-family: 'Flashit';
  font-style: normal;
  font-weight: normal;
  src: local('Flashit'), url('../Fonts/Flashit.ttf') format('woff');
}

@font-face {
  font-family: 'GONGN___';
  font-style: normal;
  font-weight: normal;
  src: local('GONGN___'), url('../Fonts/GONGN___.ttf') format('woff');
}


@font-face {
  font-family: 'Capture it';
  font-style: normal;
  font-weight: normal;
  src: local('Capture it'), url('../Fonts/Capture\ it.ttf') format('woff');
}


body {
  overflow-x: hidden;
}

* {
  font-family: "Poppins";
}

h1 {
  font-family: "Billion Dreams";
  font-size: 60px;
  font-weight: 400;
}

h2 {
  font-family: "Helvetica Rounded LT Std";
  font-size: 48px;
  font-weight: 700;
}

h3 {
  font-family: "Billion Dreams";
  font-size: 44px;
  font-weight: 400;
}

h4 {
  font-family: "Poppins";
  font-size: 22px;
  font-weight: 500;
}

h5 {
  font-family: "Helvetica Rounded LT Std";
  font-size: 21px;
  font-weight: 700;
}

p {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
}

/* font family cdn ends here */
/* global css starts here */
.btn {
  background-color: #fc9eb2;
  font-size: 17px;
  color: #fff;
  text-decoration: none;
  font-family: "Poppins";
  font-weight: 600;
  padding: 11px 25px;
  border-radius: 30px;
  cursor: pointer;
}

/* global css ends here */
/* Prelaoder */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #1297dc;
}

.banner-content-wrapper .red_font {
  font-size: 30px;
}

.ht-img-home {
  height: 360px;
  object-fit: cover;
}

.home_top_slider .slick-dots {
  display: none;
}

.HomeContent .who-we-are h2 {
  font-size: 28px;
}

/* .HomeContent .who-we-are h2:nth-child(odd) {
  padding-right: 150px !important;
  font-size: 28px;
} */

/* .HomeContent .who-we-are .row:nth-child(even) h2 {
  padding-right: 150px !important;
  font-size: 28px;
} */

.HomeContent .banner-content-wrapper .red_font {
  font-size: 32px !important;
}


.TruckPage .who-we-are .heading_wrapper {
  padding-bottom: 0px !important;
}

.footer .txt-no-trnsfrm a {
  text-transform: none !important;
}

/* zaam */
.menu_txtx{
  font-size: large;
}

.AllRoutes {
  overflow-x: hidden;
}

.HomeContent .fnt_head_sliderz_top {
  font-size: 52px !important;
  /* font-family: "PermanentMarker-Regular" !important; */
  /* font-family: "Flashit" !important; */
  /* font-family: "Helvetica Rounded LT Std" !important; */
  font-family: "Capture it" !important;
  text-transform: uppercase;
  /* padding-right: 4rem; */
  font-weight: 500 !important;
  color: white;
  /* text-shadow: 2px 3px 0px #dd8b9e; */
  text-shadow: 2px 3px 0px #00000075;
}

.HomeContent .fnt_head_sliderz_top_two {
  font-size: 52px !important;
  font-family: "Capture it" !important;
  text-transform: uppercase;
  /* padding-right: 4rem; */
  font-weight: 500 !important;
  color: white;
  /* text-shadow: 2px 3px 0px #dd8b9e; */
  text-shadow: 2px 3px 0px #00000075;
}

.HomeContent .justify-center {
  justify-content: center;
}

.HomeContent .bt-cus-icecrm {
  background-color: transparent;
  border: 2px solid;
}

.home_top_slider .Slider_top_custom_arrows {
  /* background-image: url("../Images/rarr.png"); */
  background-position: center;
  background-size: cover;
  height: 22px;
  width: 47px;
  transform: rotateY(180deg);
  position: absolute;
  top: 105%;
  left: 38%;
  color: white;
  cursor: pointer;
}

.home_top_slider .Slider_top_custom_arrows-next {
  /* background-image: url("../Images/rarr.png"); */
  background-position: center;
  background-size: cover;
  height: 22px;
  width: 47px;
  top: 106%;
  position: absolute;
  left: 50%;
  cursor: pointer;
  transform: rotate(180deg);
  color: white;
}

.all-white * {
  color: white !important;
}

.cursor_pointer {
  cursor: pointer;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #f2f2f2;
  border-top: 6px solid #ee3949;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Univeral Banner Css Start Here */
.universal_banner {
  position: relative;
}

.universal_banner .banner_img img {
  height: 560px;
  width: 100%;
}

.universal_banner .page-head {
  position: absolute;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  padding-bottom: 100px;
}

.universal_banner .page-head h2 {
  color: #fff;
  font-size: 100px;
  font-family: "Billion Dreams";
  font-weight: 500;
}

.watermark {
  position: fixed;
  top: 0;
  z-index: -1;
}

.bg-rd-btn-prof {
  background-color: #ee3949 !important;
}

/* Univeral Banner Css End Here */

/* header starts here */
header#header {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 9;
}

header#header .navbar-wrapper ul {
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding-left: 0;
}

header#header .navbar-wrapper ul li a {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  position: relative;
}

header#header .navbar-wrapper ul li a:before {
  content: "";
  background: #fff;
  width: 0%;
  height: 2px;
  position: absolute;
  bottom: -2px;
  transition: 0.6s;
}

header#header .navbar-wrapper ul li:hover a:before {
  width: 100%;
}

header#header .container-fluid {
  padding: 0px 100px;
}

header#header .btn {
  background-color: #ee3949;
  color: #fff;
}

header#header a.btn.demand {
  color: #ee3949;
  background: #fff;
}

.banner-content-wrapper button.btn {
  border: 1px solid #fff;
  background: transparent;
  cursor: pointer;
}

@media (max-width: 1400px) {
  header#header .btn {
    font-size: 12px;
    padding: 11px 12px;
  }

  header#header .navbar-wrapper ul li a {
    font-size: 14px;
  }
}

/* header ends here */
/* banner starts here */
.banner {
  background-image: url("../Images/banner-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 200px 0px;
  /* margin-top: -153px; */
}

.banner-content-wrapper h2 {
  color: #fff;
  font-size: 53px;
}

.banner-content-wrapper h2 span {
  color: white;
  -webkit-text-fill-color: #ffffff00;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  text-transform: uppercase;
  font-size: 77px;
  font-family: "Helvetica Rounded LT Std";
}

.who-we-are .wrap-blur::after {
  background: #4e85ca !important;
}

.banner-content-wrapper h1 {
  font-size: 80px;
  color: #fff;
}

.banner-content-wrapper p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
}

.banner-form-wrapper h3 {
  font-size: 50px;
  color: #fff;
}

.banner-form-wrapper h2 {
  color: #fff;
  font-size: 37px;
  font-weight: 700;
}

.banner-form-wrapper h5 {
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
}

.banner-form-content-wrapper {
  margin-bottom: 20px;
}

.banner-form-wrapper select.form-control {
  height: 50px !important;
  border-radius: 7px;
  font-size: 12px;
  /* font-family: "Helvetica Rounded LT Std"; */
  color: #c4c4c4;
  border: none;
}

.banner-form-wrapper label {
  color: #ffffff;
  font-family: "Helvetica Rounded LT Std";
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.banner-form-wrapper input.form-control {
  height: 50px !important;
  border-radius: 7px;
  font-size: 12px;
  /* font-family: "Helvetica Rounded LT Std"; */
  border: none;
}

.banner-form-wrapper .form-control::placeholder {
  color: #c4c4c4;
}

.banner-form-wrapper input.evu {
  font-size: 22px;
  font-family: "Helvetica Rounded LT Std";
  color: #2a99d2;
}

/* who we are sec starts here */
.who-we-are {
  padding: 100px 0px;
  position: relative;
}

.who-we-are .img-wrapper::after {
  content: "";
  width: 91%;
  height: 396px;
  display: block;
  background: #fbd1d6;
  position: absolute;
  top: 1px;
  z-index: -1;
  left: 22px;
  border-radius: 12px;
  transform: rotateZ(5deg);
}

.who-we-are .img-wrapper {
  padding: 12px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.who-we-are .wrap-blur::after {
  background: #4e85ca;
}

.who-we-are h3 {
  color: #fc9eb2;
}

.who-we-are h2 {
  color: #165eb9;
}

.who-we-are h5 {
  color: #165eb9;
  margin-bottom: 30px;
}

.who-we-are p {
  color: #7b7676;
}

.who-we-are img.we1 {
  position: absolute;
  bottom: 31%;
  left: -6%;
  animation: up-down linear 4s infinite;
}

.who-we-are img.we2 {
  position: absolute;
  right: 0px;
  bottom: -15%;
  z-index: -1;
  animation: up-down linear 4s infinite;
}

/* who we are sec ends here */
/* clean section starts here */
.cleanliness {
  background-image: url("../Images/clean-bg.png");
  background-repeat: no-repeat;
  background-size: 104% 100%;
  padding: 200px 0px;
  background-position: center center;
}

.cleanliness .content-wrapper h2 {
  color: #165eb9;
  font-size: 39px;
}

.cleanliness .content-wrapper p {
  color: #fff;
}

.clen h2 {
  font-size: 48px;
  color: #fff !important;
}

.clen h3 {
  color: #165eb9;
  font-size: 45px;
}

/* clean section ends here */
/* media css starts here */

.connect {
  padding-bottom: 200px;
}

.HomeContent .connect {
  padding-bottom: 0 !important;
}


.media-wrapper .card-content-wrapper {
  background: #f5f5f5;
  padding: 25px;
  margin-top: -40px;
  position: absolute;
  bottom: -71%;
  width: 95%;
  left: 3%;
}

.connect .main-content-wrapper h3 {
  color: #fc9eb2;
  font-size: 56px;
}

.connect .main-content-wrapper h2 {
  color: #165eb9;
  font-size: 48px;
}

.connect .content-wrapper h2 {
  font-size: 44px;
  color: #165eb9;
}

.connect .content-wrapper p {
  font-size: 14px;
  color: #7b7676;
}

.media-wrapper p {
  font-family: "Poppins";
  font-size: 11px;
  color: #818181;
}

.media-wrapper .card-wrapper {
  position: relative;
}

.media-wrapper .card-content-wrapper ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  padding: 0px;
}

.media-wrapper .pro-name-and-date-wrapper h6 {
  color: #939393;
  font-size: 9px;
}

/* media css ends here */
/* comming sec starts here */
.coming {
  background-image: url("../Images/coming-bg.png");
  padding: 200px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.coming h4 {
  font-size: 29px;
  font-family: "Helvetica Rounded LT Std";
  -webkit-text-fill-color: #ffffff00;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  font-weight: 700;
  text-transform: uppercase;
}

.coming h1 {
  font-family: "Billion Dreams";
  font-size: 54px;
  color: #fff;
}

.coming h1 span {
  -webkit-text-fill-color: #ffffff00;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
  font-family: "Helvetica Rounded LT Std";
}

.coming p {
  color: #fff;
  font-size: 14px;
}

.coming img.ice {
  position: absolute;
  right: 0px;
  bottom: 0%;
  animation: up-down linear 4s infinite;
}

/* comming sec ends here */
/* testimonials starts here */
.testimonials {
  padding: 250px 0px;
  position: relative;
  overflow: hidden;
}

.testimonials img.sandwish {
  position: absolute;
  right: -34%;
  top: 80%;
  width: 40%;
}

.testimonails-wrapper ul {
  padding: 0px;
  list-style: none;
  display: flex;
  align-items: center;
}

.testimonials .testimonails-item {
  padding: 20px;
  background: #165eb9;
  border-radius: 10px;
}

.testimonials .testimonails-item {
  color: #fff;
}

.testimonials .testimonails-item h6 {
  font-family: "Poppins";
  font-size: 22px;
}

.testimonials .testimonails-item span {
  font-family: "Poppins";
  font-size: 20px;
}

.testimonials .testimonails-name-wrapper {
  margin: 0px 41px;
}

.testimonials h3 {
  color: #fc9eb2;
  font-size: 50px;
}

.testimonials img.connec {
  position: absolute;
  left: 0px;
  top: 9%;
  animation: up-down linear 4s infinite;
}

.testimonials h2 {
  color: #165eb9;
  font-size: 48px;
}

.testimonials .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #165eb9;
  outline: 1px solid;
  border-radius: 50%;
  padding: 0px !important;
}

.testimonials .slick-dots li button:before {
  font-family: "slick";
  font-size: 37px;
  line-height: 17px;
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  content: "•";
  text-align: center;
  opacity: 1;
  color: #165eb9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.testimonials ul.slick-dots {
  position: absolute;
  left: -53%;
  transform: translateY(30px);
}

.testimonials .testimonails-wrapper .slick-next:before {
  color: #165eb9;
  font-size: 28px;
  position: absolute;
  left: -63rem;
  top: 10rem;
}

/* testimonials ends here */

/* feedback page starts here */
.FeedBack_Page .txt-area-fdbck {
  resize: none;
}

.FeedBack_Page .previewImageDiv {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  object-fit: cover;
}

.FeedBack_Page .uploadImg {
  position: absolute;
  position: absolute;
  bottom: -20%;
  left: 20%;
  z-index: 99;
}

@media only screen and (max-width: 991px) {
  .FeedBack_Page .uploadImg {
    position: static;
    top: -16%;
    left: 32%;
    z-index: 99;
  }
}

/* feedback page ends here */

/* .dngrslimit_home {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
} */

/* .dngrslimit_homez {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
} */


.abt-immngaz {
  height: 70px;
  width: 70px;
  object-fit: contain;
}

.ftr-logoooooooo {
  height: 155px;
  width: 100%;
  object-fit: contain;
}

.ico_foooter {
  font-size: 30px !important;
  color: white;
}

.cursor_pointer {
  cursor: pointer;
}

.book-prof-imgggg {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.MB-HOME-CONT {
  margin-bottom: 10rem;
}

footer .flx-ftr-qck-lnk {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

footer .bt-pnk-top-10 {
  position: relative;
  top: -10px;
}

footer .flx-line-bx {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

footer .rightline-ftr {
  border-right: 2px solid white;
  /* margin-top: 61px; */
  position: relative;
  top: 5px;
}

.HomeContent .testimonials {
  padding-top: 100px !important;
}

.mt-rezz-evetnone {
  margin-top: 32px;
}

.OnDemand_Page .who-we-are .img-wrapper::after {
  display: none;
}

.FAQS_PAGESS h3 span {
  font-family: "Billion Dreams";
  color: #165eb9;
}

.OnDemand_Page .who-we-are h2 {
  text-transform: uppercase;
}

.BookEvent_Page .banner-form-wrapper.scnd-step .bold_txt_highlight {
  margin: 0;
  font-size: 11px;
  color: #000;
  font-weight: 700;
  padding: 15px 0;
}
section.book-event .row {
  display: flex;
  align-items: center;
}

.header_comp .profile_image_headers {
  height: 30px;
  width: 30px;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
  top: -7px;
}

.img_entr_email {
  width: 120px !important;
}

.HomeContent .txt_aboutSec_centr {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.header_comp .brdr_actv_linezz {
    border-bottom: 2px solid #fff;
}

/* .AboutPage .who-we-are .row:nth-child(1) h2{
  padding-right: 25rem!important;
} */

._2p3a{
  margin: auto!important;
}

/* home zaam works ends */

/* foooter css starts here */
footer.footer {
  background-image: url("../Images/footer-bg.png");
  padding-top: 303px;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

footer .margn-lft-3rm {
  margin-left: 2.5rem;
}

.Fill_In_Your_Information {
  font-size: larger;
}

footer .footer-widget-1-wrapper {
  margin-top: -80px;
}

footer.footer ul {
  list-style: none;
  padding: 0px;
}

.footer-widget-2-wrapper ul {
  column-count: 3;
  margin-top: 39px;
}

footer.footer ul li a {
  font-size: 12.5px;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
}

footer.footer ul li a {
  color: #fff;
  text-transform: capitalize;
}

.footer-widget-3-wrapper .footer-links-wrapper {
  margin-top: 34px;
}

footer.footer ul li {
  color: #fff;
  font-size: 15px;
  line-height: 1.6;
  margin: 4px 0;
}

footer.footer h4 {
  color: #ffffff;
  text-decoration: underline;
}

div#copyright {
  position: relative;
  padding-bottom: 30px;
}

div#copyright p {
  color: #fff;
}

div#copyright a {
  color: #fff;
  text-decoration: none;
}

#copyright img {
  width: 71%;
  animation: up-down linear 4s infinite;
}

.rights-text {
  color: #fff;
}

.ondemand_image {
  height: auto;
  width: 50%;
  z-index: 99;
  /* object-fit: contain; */
}

.bg-size-cover {
  background-size: cover !important;
  z-index: -1;
}

.OnDemand_Page .coming h1 {
  font-size: 54px;
}

.form-group .from_end_time {
  padding: 1.15rem;
  border: 1px solid #c2c2c2 !important;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 0;
  padding-left: 0.8rem;
  color: #c4c4c4 !important;
  letter-spacing: 1px;
  font-size: 15px !important;
}

/* foooter css ends here */
/* animation starts here */
@keyframes mymove {
  50% {
    transform: rotate(180deg);
  }
}

@keyframes up-down {
  0% {
    transform: translate(1px, 20px);
  }

  24% {
    transform: translate(1px, 30px);
  }

  50% {
    transform: translate(1px, 12px);
  }

  74% {
    transform: translate(1px, 22px);
  }

  100% {
    transform: translate(1px, 22px);
  }
}

/* Begin of Other Css */
.hidden-class {
  opacity: 0;
}
/* ============= */